.footer {
  height: 40px;
  background-color: #001529;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer .logo {
  max-height: 50%;
}
