.drafts-view-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.drafts-container {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0.5em;
}

.drafts-wrapper {
  flex: 1;
}

.draft-preview-group {
  margin: 4px;
}
