.home-view-wrapper {
  height: calc(100% - 40px); /* minus footer's height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 20px 40px;
}

.home-view-wrapper .content{
  width: 95%;
}

.create-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.drafts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1%;
}

.previews-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.home-header, h3{
  text-align: center;
}
